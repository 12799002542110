import * as React from 'react';
import { useTranslation } from 'react-i18next';
import DefaultLayout from '../../components/layouts/default-layout';
import ToolHelp from '../../components/tools/toolHelp';
import ToolsList from '../../components/tools/toolsList';
import { VoidPageProps } from '../../utils/graphql';
import useElementOnScreen from '../../utils/useElementOnScreen';

const ToolsIndex: React.VoidFunctionComponent<VoidPageProps> = ({ pageContext }) => {
  const { t } = useTranslation('tools');

  const tools = Object.keys(
    t<string, { [key: string]: void }>('tools', {
      returnObjects: true,
      defaultValue: {},
    }),
  );

  const pageTitle = t('index.head-title');

  const [animatedElement, isInView] = useElementOnScreen<HTMLDivElement>();

  return (
    <DefaultLayout
      headTitle={pageTitle}
      headDescription={t('index.head-description')}
      lang={pageContext.locale}
      wrapInContainer
    >
      <div className="mb-9 text-black">
        <div
          className={`translate-y-12 opacity-0  ${isInView ? 'animate-slidein' : ''}`}
          ref={animatedElement}
        >
          <h1 className="md:mb-8">{t('index.header-title')}</h1>
          <h2 className="text-xl font-semibold sm:text-3xl md:mb-8 md:mt-8">
            {t('index.tools-available', { count: tools.length })}
          </h2>
        </div>
        <ToolsList tools={tools} />
      </div>
      <ToolHelp pageTitle={pageTitle} />
    </DefaultLayout>
  );
};

export default ToolsIndex;
