import * as React from 'react';
import { useTranslation } from 'react-i18next';
import Card from '../Card';
import { BLACK_ON_YELLOW, BLUE_ON_WHITE, WHITE_ON_BLUE, YELLOW_ON_BLACK } from '../Colors';
import Tags from '../Tags';

type ToolItemProps = {
  toolId: string;
};

const defaultTheme = [WHITE_ON_BLUE, BLUE_ON_WHITE];

const themes = {
  internal: defaultTheme,
  template: [BLACK_ON_YELLOW, YELLOW_ON_BLACK],
};

type ToolType = keyof typeof themes;

const ToolCard: React.VoidFunctionComponent<ToolItemProps> = ({ toolId }) => {
  const { t } = useTranslation('tools');

  const toolTitle = t(`tools.${toolId}.header-title`);
  const [className, tagClassName] =
    themes[t<string, ToolType>(`tools.${toolId}.type`)] || defaultTheme;

  const tags = t<string, string[]>(`tools.${toolId}.tags`, {
    returnObjects: true,
    defaultValue: [],
  });

  return (
    <Card title={toolTitle} linkTo={`/tools/${toolId}/`} themeClassName={className}>
      <div className="flex flex-col md:flex-row md:justify-end">
        <div className="grow">
          <h3 className="m-0 text-left text-2xl font-semibold sm:text-3xl">{toolTitle}</h3>
          <h4 className="m-0 whitespace-pre-line text-left text-base font-medium sm:text-xl">
            {t(`tools.${toolId}.short-description`)}
          </h4>
        </div>
        <div className="my-4 flex flex-wrap md:my-0 md:content-evenly md:items-center md:justify-end">
          <Tags tags={tags} tagClassName={tagClassName} />
        </div>
      </div>
    </Card>
  );
};

export default ToolCard;
