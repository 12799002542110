import * as React from 'react';
import ToolCard from './toolCard';

const ToolsList: React.VoidFunctionComponent<{ tools: string[] }> = ({ tools }) => {
  return (
    <>
      {tools.map((tool) => (
        <ToolCard key={tool} toolId={tool} />
      ))}
    </>
  );
};

export default ToolsList;
